import React, { useState } from "react"
import style from "./plan.mod.scss"
import { checkoutUser } from "@helpers/userHelpers"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Plan = ({
  name,
  label,
  key,
  price,
  description,
  onClick,
  hasTrial,
  customerId,
  planId,
  redirectUrl,
  cancelUrl,
  specialOffer = false,
  hasLabel,
  colorLabel
}) => {
  const data = useStaticQuery(graphql`
    {
      customBrand: datoCmsMiscContent {
        logo {
          gatsbyImageData(imgixParams: { w: "75", fit: "crop" })
        }
      }
    }
  `)

  const [loading, setLoading] = useState(false)
  const classes = [style.plan]
  specialOffer && classes.push(`${style[`plan`]}--special-offer`)
  colorLabel && classes.push(`${style[`plan`]}--label-${colorLabel}`)

  const handleClick = () => {
    setLoading(true)
    checkoutUser(customerId, planId, redirectUrl, cancelUrl)
  }
  const removeAU = inputString => inputString?.replace(/AU/g, "").trim()

  const newLabel = removeAU(label)
  const newName = removeAU(name)

  return (
    <button
      key={key}
      type={"button"}
      className={[...classes].join(" ")}
      onClick={handleClick}
    >
      {specialOffer && (
        <GatsbyImage
          image={data.customBrand.logo.gatsbyImageData}
          className={style["plan__brand"]}
        />
      )}
      {hasTrial && <span className={style["plan__label"]}>{"Free trial"}</span>}
      <div className={style["plan__top"]}>
        <p className={style["plan__name"]}>{newLabel || newName}</p>
        <p className={style["plan__price"]}>
          {price === 0 ? "FREE" : `$${(price / 100).toFixed(2)}`}
          {/* {hasTrial && "*"} */}
        </p>
      </div>
      {loading ? (
        <p className={style.plan__description} children={"Checking out... "} />
      ) : (
        <p className={style.plan__description} children={description} />
      )}
    </button>
  )
}

export default Plan
